document.querySelectorAll('[data-modal]').forEach((el) => {
    el.addEventListener('click', function () {
        document.getElementById(el.dataset.modal).classList.toggle('modal--open');
        document.body.classList.add('adam__modal__show');
    }, false);
});

document.querySelectorAll('.adam__modal__dialog').forEach((el) => {
    el.addEventListener('click', function (e) {
        e.stopPropagation();
    });
});

document.querySelectorAll('.adam__modal').forEach((el) => {
    el.addEventListener('click', function () {
        el.closest('.adam__modal').classList.toggle('modal--open');
        document.body.classList.remove('adam__modal__show');
    });
});

document.querySelectorAll('.adam__modal__close').forEach((el) => {
    el.addEventListener('click', function () {
        el.closest('.adam__modal').classList.toggle('modal--open');
        document.body.classList.remove('adam__modal__show');
    }, false);
});